import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";

import { saasClassicTheme } from "common/theme/saasClassic";
import { ResetCSS } from "common/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper,
} from "containers/SaasClassic/saasClassic.style";
import { purchaseCouponStyle } from "containers/SaasClassic/purchaseCode.style";
import HowWorks from "../Containers/HowWorks";

import "@redq/reuse-modal/es/index.css";
import Seo from "components/seo";
import Footer from "../Containers/Footer";
import Navbar from "../Containers/Navbar";

const PurchaseCoupon = ({ location }) => {
  const [coupon, setCoupon] = React.useState(null);
  const [lang, setLang] = React.useState("He");

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    //?tourID=IDtest&coupon=cTest&source=sTest&deviceID=dTest&to_email=shemesh1500@gmail.com&lang=En
    const tourID = params.get("tourID");
    setCoupon(params.get("coupon"));
    //const source = params.get("source");
    const wantLang = params.get("lang");
    //let deviceID = params.get("deviceID");
    //let approval_num = params.get("approval_num");
    //let date = params.get("date");
    //let lang = params.get("lang");

    if (wantLang && (wantLang === "He" || wantLang === "En")) setLang(wantLang);
    /*if (calcBitCheck === Number(bCheck)) {
      if (!source) {
        source = "Dguide";
      }
      setCoupon(createCoupon(t_id));
      fetch(
        `https://europe-west1-tours-app-1579553856346.cloudfunctions.net/newPurchase/?tourID=${t_id}&coupon=${coupon}&source=${source}&deviceID=${bCheck}&guideId=${guideId}&totalA=${totalA}`
      ).then((response) => console.log("RESPONS", response));
    } */
  }, [location.search]);

  /*   const cleanHTML = DOMPurify.sanitize(
    lang === "He" ? HTMLcodeHe : HTMLcodeEn,
    {
      USE_PROFILES: { html: true },
    }
  ); */

  return (
    <ThemeProvider theme={saasClassicTheme}>
      <Fragment>
        <Seo
          title={`Dguide | Purchase page}`}
          description={"אישור תשלום"}
          lang={"He"}
          meta={[]}
          keywords={[]}
        />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper style={{ direction: lang === "He" ? "rtl" : "ltr" }}>
          {/* <purchaseCouponStyle>
            <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
          </purchaseCouponStyle> */}
          <Navbar returnPayment={true} />
          <HowWorks lang={lang} coupon={coupon} />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default PurchaseCoupon;
