import React from "react";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Icon } from "react-icons-kit";
import { arrowRight } from "react-icons-kit/feather/arrowRight";
import { arrowLeft } from "react-icons-kit/feather/arrowLeft";
import Container from "common/components/UI/Container";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Image from "common/components/Image";
import Box from "common/components/Box";
import HowWorksWrapper from "./howWorks.style";
import pic1 from "../../images/step1_2.jpg";
import pic2 from "../../images/step2.jpg";
import pic3 from "../../images/step3.jpg";

const HowWorks = ({ lang, coupon }) => {
  const text = {
    bigTitle: {
      He: "אז איך מתחילים את הסיור?",
      En: "So how to start the tour?",
    },
    title1: { He: "צעד ראשון", En: "First Step" },
    title2: { He: "צעד שני", En: "Second Step" },
    title3: { He: "צעד שלישי", En: "Third Step" },
    text1: { He: "מורידים את האפליקציה", En: "Download Dguide APP" },
    text2: {
      He: "הזינו את הקוד הבא בדף הראשי והסיור שלך יהיה זמין בדף הסיורים שלך",
      En: "Type the next coupon at the main page in the app, the tour will be available for you at your profile page",
    },
    text3: {
      He: "התחילו את הסיור כמה פעמים שתרצו ב 14 ימים בקרובים",
      En: "Start the tour as much as you want to in the next 14 days",
    },
    androidLink: { He: "לינק לאנדרואיד", En: "Android Link" },
    iosLink: { He: "לינק לאפל", En: "IOS Link" },
  };

  return (
    <HowWorksWrapper style={{ direction: lang === "He" ? "rtl" : "ltr" }}>
      <Container>
        <Heading as="h2" content={text["bigTitle"][lang]} />
        <Box className="postWrap">
          <Box className="post" key={`how-works-post-key-1`}>
            <Fade bottom cascade>
              <Box className="imageBox">
                <Image src={pic1} alt={text["title1"][lang]} />
              </Box>
              <Heading as="h3" content={text["title1"][lang]} />
              <Text as="p" content={text["text1"][lang]} />
              <Link className="button" to="https://bit.ly/3qqKOx5">
                {text["androidLink"][lang]}{" "}
                <Icon size={18} icon={lang === "He" ? arrowLeft : arrowRight} />
              </Link>
              <Link className="button" to="https://apple.co/3u4oUSp">
                {text["iosLink"][lang]}{" "}
                <Icon size={18} icon={lang === "He" ? arrowLeft : arrowRight} />
              </Link>
            </Fade>
          </Box>
          <Box className="post" key={`how-works-post-key-2`}>
            <Fade bottom cascade>
              <Box className="imageBox">
                <Image src={pic2} alt={text["title2"][lang]} />
              </Box>
              <Heading as="h3" content={text["title2"][lang]} />
              <Text as="p" content={text["text2"][lang]} />
              <Heading as="h1" content={coupon} />
            </Fade>
          </Box>
          <Box className="post" key={`how-works-post-key-3`}>
            <Fade bottom cascade>
              <Box className="imageBox">
                <Image src={pic3} alt={text["title3"][lang]} />
              </Box>
              <Heading as="h3" content={text["title3"][lang]} />
              <Text as="p" content={text["text3"][lang]} />
            </Fade>
          </Box>
          {/*posts.map(({ icon, title, text, link }, index) => (
            <Box className="post" key={`how-works-post-key-${index}`}>
              <Fade bottom cascade>
                <Box className="imageBox">
                  <Image src={pic1} alt={title} />
                </Box>
                <Heading as="h3" content={title} />
                <Text as="p" content={text} />
                <Link className="button" to={link.path}>
                  {link.label} <Icon size={18} icon={arrowRight} />
                </Link>
              </Fade>
            </Box>
          ))*/}
        </Box>
      </Container>
    </HowWorksWrapper>
  );
};

export default HowWorks;
